import React, { createContext, useContext, useState, ReactNode, useCallback } from 'react'
import { Snackbar, Alert, Typography } from '@mui/material'

type SnackbarMessage = {
  message: ReactNode
  variant: 'success' | 'error' | 'warning'
  autoHide: boolean
}

type SnackbarContextType = {
  showSuccess: (message: string, autoHide?: boolean) => void
  showError: (message: string, autoHide?: boolean) => void
  showWarning: (message: string, autoHide?: boolean) => void
}

const SnackbarContext = createContext<SnackbarContextType | undefined>(undefined)

export const SnackbarProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [open, setOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState<SnackbarMessage | null>(null)

  const formatMessage = (message: string): ReactNode => {
    if (message.includes('\n')) {
      return (
        <Typography>
          {message.split('\n').map((line, index) => (
            <span key={index}>
              {line}
              <br />
            </span>
          ))}
        </Typography>
      )
    }
    return message
  }

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  const showSnackbar = useCallback((message: string, variant: SnackbarMessage['variant'], autoHide: boolean = true) => {
    setSnackbarMessage({ message: formatMessage(message), variant, autoHide })
    setOpen(true)
  }, [])

  const showSuccess = useCallback((message: string, autoHide: boolean = true) => {
    showSnackbar(message, 'success', autoHide)
  }, [showSnackbar])

  const showError = useCallback((message: string, autoHide: boolean = true) => {
    showSnackbar(message, 'error', autoHide)
  }, [showSnackbar])

  const showWarning = useCallback((message: string, autoHide: boolean = true) => {
    showSnackbar(message, 'warning', autoHide)
  }, [showSnackbar])

  return (
    <SnackbarContext.Provider value={{ showSuccess, showError, showWarning }}>
      {children}
      <Snackbar
        open={open}
        autoHideDuration={snackbarMessage?.autoHide ? 8000 : null}
        onClose={handleClose}
      >
        <Alert 
          onClose={handleClose} 
          severity={snackbarMessage?.variant} 
          variant="filled"
          style={{ maxHeight: '200px', overflowY: 'auto' }}
        >
          {snackbarMessage?.message}
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  )
}

export const useSnackbar = (): SnackbarContextType => {
  const context = useContext(SnackbarContext)
  if (!context) {
    throw new Error('useSnackbar must be used within a SnackbarProvider')
  }
  return context
}