import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import GroupIcon from '@mui/icons-material/Group'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid2'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import {
    DataGrid,
    GridActionsCellItem,
    GridColDef,
    GridRowId,
    GridRowParams,
    GridRowSelectionModel,
    GridToolbar,
} from '@mui/x-data-grid'
import { AxiosError } from 'axios'
import { FC, useEffect, useState } from 'react'
import { axiosPrivate } from '../../api/axios'
import { endpoints } from '../../api/constants'
import DataGridToolbar from '../../components/datagridtoolbar'
import { useAuth } from '../../context/AuthProvider'
import { useDailyWorkReport } from '../../context/DailyWorkReportProvider'
import { useSnackbar } from '../../context/SnackbarProvider'
import { getDisplayName } from '../../types/Employee'
import { EmployeeJobType, JournalDailyWorkDetail } from '../../types/Journal'
import { generateUniqueID } from '../../utils/common'
import { fNumber } from '../../utils/format-number'

interface EmployeeWorkDetailsProps {  
  validateEmployeeWorkDetails: boolean
}

enum EditableFields {
  EmployeeJobType = 'employeeJobType',
  JobQuantity = 'jobQuantity',
  JobPrice = 'jobPrice',
}

const EmployeeWorkDetails: FC<EmployeeWorkDetailsProps> = ({  
  validateEmployeeWorkDetails,
}) => {
  const {
    journalDailyWorkDetails,
    setJournalDailyWorkDetails,
    setSelectedEmployees,
  } = useDailyWorkReport()
  const [openModal, setOpenModal] = useState(false)
  const [editRow, setEditRow] = useState<JournalDailyWorkDetail | null>(null)
  const [selectedRows, setSelectedRows] = useState<GridRowId[]>([])
  const [filteredJournalDailyWorkDetails, setFilteredJournalDailyWorkDetails] = useState<JournalDailyWorkDetail[]>([])
  const [isBulkEdit, setIsBulkEdit] = useState(false)
  const { auth } = useAuth()
  const { selectedTenant } = auth
  const { showError } = useSnackbar()    
  const [employeeJobTypes, setEmployeeJobTypes] = useState<EmployeeJobType[]>([])  

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()       

    const getEmployeeJobTypes = async () => {
      try {
        const response = await axiosPrivate.get(`${endpoints.getEmployeeJobTypes}${selectedTenant?.id}`,{ signal: controller.signal }
        )
        if (isMounted) {
          setEmployeeJobTypes(response.data)
        }
      } catch (err: any) {
        const error = err as AxiosError
        if (error.name !== 'CanceledError') {
          showError('Error al cargar los tipos de trabajo de los empleados')
        }
      }
    }                  
    
    getEmployeeJobTypes()       

    return () => {
      isMounted = false
      controller.abort()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTenant?.id])
  
  useEffect(() => {
    setFilteredJournalDailyWorkDetails(journalDailyWorkDetails)
  }, [journalDailyWorkDetails])
  
  const handleBulkEdit = () => {
    if (selectedRows.length > 0) {
      setIsBulkEdit(true)
      setOpenModal(true)
    }
  } 

  const handleDuplicate = () => {
    if (selectedRows.length > 0) {
      let updatedDetails = [...journalDailyWorkDetails]   

        // Iterate in reverse to maintain order when inserting elements
      for (let i = journalDailyWorkDetails.length - 1; i >= 0; i--) {
        const detail = journalDailyWorkDetails[i]
        if (selectedRows.includes(detail.id)) {
          const duplicatedDetail = {
            ...detail,
            employeeJobType: null,
            jobQuantity: 0,
            id: generateUniqueID(),
          }
          // Insert the duplicated row right after the original
          updatedDetails.splice(i + 1, 0, duplicatedDetail)
        }
      }
  
      setJournalDailyWorkDetails(updatedDetails)
      setSelectedRows([])
    }
  }

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    event.target.select()
  } 

  const handleEditRow = (id: GridRowId) => () => {
    const row = journalDailyWorkDetails.find(      
      (row) => row.id === id      
    ) as JournalDailyWorkDetail
        setEditRow({
          ...row,
          employeeJobType: employeeJobTypes.find(
            (jobType) => jobType.id === row.employeeJobType?.id
          ) || null,        
        })      
      setOpenModal(true)
  }

  const handleCloseModal = () => {
    setOpenModal(false)
    setEditRow(null)
    setIsBulkEdit(false)
  }

  const handleSaveModal = () => {
    if (isBulkEdit && editRow) {
      const updatedDetails = journalDailyWorkDetails.map(detail => {
        if (selectedRows.includes(detail.id)) {
          return {
            ...detail,           
            employeeJobType: editRow.employeeJobType || null,
            jobQuantity: editRow.jobQuantity || 0,
            jobPrice: editRow.employeeJobType?.jobPrice.price || 0,
          }
        }
        return detail
      })
      setJournalDailyWorkDetails(updatedDetails)
      setSelectedRows([])
    } else if (editRow) {      
      setJournalDailyWorkDetails(
        journalDailyWorkDetails.map(row =>
          row.id === editRow.id
          ? { ...editRow, jobPrice: editRow.employeeJobType?.jobPrice.price || 0 }
          : row
        )
      )
    }
    handleCloseModal()
  }

  const handleEditSelectChange = (prop: EditableFields) =>  (event: SelectChangeEvent) => {
    setEditRow({
      ...editRow!,
      [prop]: employeeJobTypes.find(
        (jobType) => jobType.id === parseInt(event.target.value as string)
      ),
    })
  }

  const handleEditjobQuantityChange = (prop: EditableFields) => (event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setEditRow({ ...editRow!, [prop]: event.target.value })
  }  
  
  const updateUniqueEmployees = (updatedDetails: JournalDailyWorkDetail[]) => {
    const uniqueEmployeeIds = new Set()
    const uniqueEmployees = updatedDetails.filter(detail => {
      const isUnique = !uniqueEmployeeIds.has(detail.employee.id)
      uniqueEmployeeIds.add(detail.employee.id)
      return isUnique
    })
  
    setSelectedEmployees(uniqueEmployees.map(detail => detail.employee))
  }
  
  const handleDeleteRow = (id: GridRowId) => () => {
    const updatedDetails = journalDailyWorkDetails.filter((row) => row.id !== id)
    setJournalDailyWorkDetails(updatedDetails)
    updateUniqueEmployees(updatedDetails)
  }
  
  const handleBulkDelete = () => {
    const updatedDetails = journalDailyWorkDetails.filter(
      detail => !selectedRows.includes(detail.id)
    )
    setJournalDailyWorkDetails(updatedDetails)
    updateUniqueEmployees(updatedDetails)
  }

  const validateEmployeeWorkDetailsError = () => {
    if (!validateEmployeeWorkDetails) {
      return null
    }

    const isEmployeesEmpty = journalDailyWorkDetails.length === 0

    if (isEmployeesEmpty) {
      return <Typography sx={{ml: 1}} color="error">Atención: Debe agregar al menos un empleado para continuar.</Typography>
    }

    const isInvalid = journalDailyWorkDetails.some(detail => 
      detail.employeeJobType === null || detail.jobQuantity === 0
    )

    if (isInvalid) {
      return <Typography sx={{ml: 1}} color="error">Atención: Todos los empleados deben tener un trabajo asignado y una cantidad mayor a cero.</Typography>
    }  

    return null
  }

  const columns: GridColDef<JournalDailyWorkDetail>[] = [
    {
      headerName: 'Nombre',
      field: 'fullName',
      valueGetter: (value, row) => getDisplayName(row.employee),
      minWidth: 150,
      flex: 1,
    },
    {
      headerName: 'Trabajo',
      field: 'employeeJobType',      
      valueGetter: (value, row) => row.employeeJobType?.name,
      minWidth: 140,
      flex: 1,  
      renderCell: (params) => ( // Text wrapping
        <div style={{ whiteSpace: 'normal', lineHeight: 'normal' }}> 
          {params.value}
        </div>
      )
    },
    {
      headerName: 'Cantidad',
      field: 'jobQuantity',
      minWidth: 128,
      flex: 1,
      valueGetter: (value, row) => fNumber(value)   
    },
    {
      headerName: 'Precio',
      field: 'jobPrice',
      minWidth: 128,
      flex: 1,
      valueGetter: (value, row) => fNumber(value)
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: '',
      width: 55,
      getActions: (params: GridRowParams<JournalDailyWorkDetail>) => [
        <GridActionsCellItem sx={{ p: 0, m: 0 }} 
          icon={<EditIcon />}
          label='Editar'
          className='textPrimary'
          onClick={handleEditRow(params.row.id)}
          color='inherit'
        />,
        <GridActionsCellItem sx={{ p: 0, m: 0 }}
          icon={<DeleteIcon color="error" />}
          label='Eliminar'
          onClick={handleDeleteRow(params.row.id)}
          color='inherit'
        />,
      ],
    },
  ]

    return (
      <Grid container spacing={3} sx={{ mt: 1, mb: 1 }}>
        <Grid size={12}>
          {validateEmployeeWorkDetailsError()}
          <DataGrid
            rows={filteredJournalDailyWorkDetails}
            columns={columns}
            checkboxSelection
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            disableRowSelectionOnClick
            density='compact'
            slots={{
              toolbar: () =>
                selectedRows.length === 0 ? (
                  <GridToolbar
                    showQuickFilter
                    quickFilterProps={{ debounceMs: 300, placeholder: 'Buscar' }}
                    printOptions={{ disableToolbarButton: true }}
                    csvOptions={{ disableToolbarButton: true }}
                  />
                ) : (
                  <DataGridToolbar
                    numSelected={selectedRows.length}                    
                    onEdit={handleBulkEdit}
                    onDelete={handleBulkDelete}
                    additionalActionButton={{
                      tooltipTitle: 'Duplicar',
                      icon: <GroupIcon />,
                      action: handleDuplicate,
                    }}
                  />
                ),
            }}
            rowSelectionModel={selectedRows}
            onRowSelectionModelChange={(
              newSelection: GridRowSelectionModel
            ) => {
              setSelectedRows([...newSelection])
            }}
          />
        </Grid>
        <Dialog open={openModal} onClose={handleCloseModal}>
          <DialogTitle>Asignar trabajo</DialogTitle>
          <DialogContent>
            <FormControl fullWidth margin='dense' variant='outlined'>
              <InputLabel id='job-type-select-label'>
                Trabajo realizado
              </InputLabel>
              <Select
                labelId='job-type-select-label'
                id='job-type-select'
                value={editRow?.employeeJobType?.id.toString() || ''}
                label='Trabajo realizado'
                onChange={handleEditSelectChange(
                  EditableFields.EmployeeJobType
                )}
              >
                {employeeJobTypes.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              sx={{ mt: 1, mb: 1 }}
              size='small'
              id='outlined-read-only-employeeJobType-price'
              label='Precio'
              variant='filled'
              value={fNumber(editRow?.employeeJobType?.jobPrice?.price || 0)}
              InputProps={{
                readOnly: true,
              }}
              fullWidth
            />
            <TextField
              margin='dense'
              label='Cantidad'
              type='number'
              fullWidth
              variant='outlined'
              value={editRow?.jobQuantity || 0}
              onChange={handleEditjobQuantityChange(EditableFields.JobQuantity)}
              onFocus={handleFocus}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseModal}>Cancelar</Button>
            <Button onClick={handleSaveModal}>Actualizar</Button>
          </DialogActions>
        </Dialog>
      </Grid>
    )
}

export default EmployeeWorkDetails
