import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft'
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import EditIcon from '@mui/icons-material/Edit'
import { Avatar, Box, CardHeader, Checkbox, Chip, FormControl, IconButton, Input, InputLabel, List, ListItem, ListItemButton, ListItemIcon, ListItemText, MenuItem, Select, Switch, Tooltip } from '@mui/material'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Container from '@mui/material/Container'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid2'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRowId,
  GridRowParams,
  GridToolbar,
} from '@mui/x-data-grid'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { DateValidationError } from '@mui/x-date-pickers/models'
import { AxiosError } from 'axios'
import dayjs, { Dayjs } from 'dayjs'
import { FC, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ApiError, axiosPrivate } from '../../api/axios'
import { endpoints } from '../../api/constants'
import DynamicDialog from '../../components/DynamicDialog'
import Iconify from '../../components/iconify'
import Loading from '../../components/Loading/Loading'
import { Tenant, useAuth } from '../../context/AuthProvider'
import { useSnackbar } from '../../context/SnackbarProvider'
import { Employee, EmployeeDetails, EmployeeGender, EmployeePaymentType, getFullName, getGenderDescription } from '../../types/Employee'
import { fDateShort, formatDateTimeCustom } from '../../utils/format-time'
import { constants } from '../contants'
import AddEmployeePaymentTypeDialog from './AddEmployeePaymentTypeDialog'

const EmployeeEditView: FC = () => {
  const { id } = useParams<{ id: string }>()
  const navigate = useNavigate()
  const { auth } = useAuth()
  const { showError, showSuccess, showWarning } = useSnackbar()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [employee, setEmployee] = useState<EmployeeDetails | null>(null)
  const [idDocument, setIdDocument] = useState<string>('')
  const [birthDate, setBirthDate] = useState<Dayjs | null>(null)
  const [dateError, setDateError] = useState<DateValidationError | null>(null)
  const [isDateEmpty, setIsDateEmpty] = useState<boolean>(birthDate === null)
  const minDate = dayjs().subtract(90, 'year')
  const maxDate = dayjs().subtract(5, 'year')
  const [firstName, setFirstName] = useState<string>('')
  const [lastName, setLastName] = useState<string>('')
  const [gender, setGender] = useState<string>('')
  const [address, setAddress] = useState<string>('')
  const [availableTenants, setAvailableTenants] = useState<Tenant[]>([])
  const [employeeTenants, setEmployeeTenants] = useState<Tenant[]>([])
  const [checked, setChecked] = useState<readonly Tenant[]>([])
  const employeeTenantsChecked = intersection(checked, employeeTenants)
  const availableTenantsChecked = intersection(checked, availableTenants)
  const [accountNumberMaxLength, setAccountNumberMaxLength] = useState<number>(0)
  const [selectedImage, setSelectedImage] = useState<string | null>(null)
  const [employeePaymentTypes, setEmployeePaymentTypes] = useState<EmployeePaymentType[]>([])
  const [editMode, setEditMode] = useState(false);
  const [selectedPaymentType, setSelectedPaymentType] = useState<EmployeePaymentType | null>(null);  
  const [openAddPaymentTypeDialog, setOpenAddPaymentTypeDialog] = useState(false);

  const handleAddPaymentType = (newPaymentType: EmployeePaymentType) => {
    setEmployeePaymentTypes((prev) => [...prev, newPaymentType]);
  };

  const handleEditPaymentType = (updatedPaymentType: EmployeePaymentType) => {
    setEmployeePaymentTypes((prev) =>
      prev.map((paymentType) =>
        paymentType.id === updatedPaymentType.id ? updatedPaymentType : paymentType
      )
    );
  };

  const handleEditRow = (paymentType: EmployeePaymentType) => {
    setSelectedPaymentType(paymentType);
    setEditMode(true);
    setOpenAddPaymentTypeDialog(true);
  };
  

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0]
      const reader = new FileReader()
  
      reader.onload = (e) => {
        if (e.target?.result) {
          setSelectedImage(e.target.result as string)
        }
      }
      reader.readAsDataURL(file)
    }
  }

  const handleDeletePaymentType = (id: GridRowId) => {
    setEmployeePaymentTypes((prev) => {
      const updatedPaymentTypes = prev.filter((paymentType) => paymentType.id !== id)
      const hasDefault = updatedPaymentTypes.some((paymentType) => paymentType.isDefault)
  
      if (!hasDefault && updatedPaymentTypes.length > 0) {
        updatedPaymentTypes[0].isDefault = true
        showWarning('Atención: Dado que se eliminó el método de pago en uso, se ha seleccionado uno por defecto')
      }
  
      return updatedPaymentTypes
    })
  }
  const getEmployeeDetails = async (controller: AbortController): Promise<EmployeeDetails | undefined> => {
    try {
      const response = await axiosPrivate.get(
        `${endpoints.getEmployee}${id}`,
        { signal: controller.signal }
      )      
      setEmployee(response.data)
      return response.data as EmployeeDetails
    } catch (err: any) {    
      const error = err as AxiosError
      if (error.name !== 'CanceledError') {
        showError('Error al obtener los detalles del empleado')
      }
      return undefined
    }
  }

  const getTenants = async (controller: AbortController, employeeTenants: Tenant[])  => {
    try {
      const response = await axiosPrivate.get(endpoints.getTenants, {
        signal: controller.signal,
      })
      setAvailableTenants(
        response.data.filter(
          (tenant: Tenant) =>
            !employeeTenants.some((empTenant) => empTenant.id === tenant.id)
        )
      )         
    } catch (err: any) {
      const error = err as AxiosError
      if (error.name !== 'CanceledError') {
        showError('Error al cargar los perfiles')
      }
    }
  }
  
  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()
    setIsLoading(true)

    const fetchData = async () => {
      if (isMounted) {
        const employeeDetails = await getEmployeeDetails(controller)
        if (isMounted) {
          await getTenants(controller, employeeDetails?.tenants || [])
        }
      }
      setIsLoading(false)
    }

    fetchData()

    return () => {
      isMounted = false
      controller.abort()
      setIsLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (employee) {      
      setIdDocument(employee.idDocument)
      setBirthDate(dayjs(employee.birthDate))
      setIsDateEmpty(employee.birthDate === null || employee.birthDate === '')
      setFirstName(employee.firstName)
      setLastName(employee.lastName)
      setGender(employee.gender)
      setAddress(employee.address)
      setEmployeeTenants(employee.tenants)
      setEmployeePaymentTypes(employee.employeePaymentTypes)
      const maxLength = employee.employeePaymentTypes.length > 0 
      ? employee.employeePaymentTypes.reduce((max, type) => {
          const accountNumberLength = type.employeeBankAccount?.accountNumber?.length || 0
          return Math.max(max, accountNumberLength)
        }, 0)
      : 12
    setAccountNumberMaxLength(Math.max(maxLength, 12))
    }
  }, [employee])

  const handleDateChange = (newValue: Dayjs | null) => {
    setBirthDate(newValue)
    if (!newValue) {
      setIsDateEmpty(true)
      setDateError(null)
    } else {
      setIsDateEmpty(false)
      setDateError(null)
    }
  }

    const dateErrorMessage = useMemo(() => {    
      if (isDateEmpty) return constants.requiredFieldMessage
  
      switch (dateError) {
        case 'maxDate':
          return `La fecha no puede ser mayor a ${maxDate.format('DD-MM-YYYY')}`
        case 'minDate':
          return `La fecha no puede ser menor a ${minDate.format('DD-MM-YYYY')}`
        case 'invalidDate':
          return 'La fecha ingresada no es válida'
        default:
          return ''
      }
    }, [dateError, isDateEmpty, maxDate, minDate])


  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()   

    console.log('Submit')

    // const productInventory: EditProductRequest = {
    //   id: currentProduct.id,
    //   name,
    //   unitPrice,
    //   quantity,
    //   description,
    //   updatedByUserEmail: auth.user?.email || '', //TODO: Validate user
    // }
   
    // EditProduct(productInventory)
  }

  function not(a: readonly Tenant[], b: readonly Tenant[]) {
    return a.filter((tenant) => !b.some((t) => t.id === tenant.id))
  }
  
  function intersection(a: readonly Tenant[], b: readonly Tenant[]) {
    return a.filter((tenant) => b.some((t) => t.id === tenant.id))
  }
  
  function union(a: readonly Tenant[], b: readonly Tenant[]) {
    return [...a, ...not(b, a)]
  }

  const handleToggle = (tenant: Tenant) => () => {
    const currentIndex = checked.findIndex((t) => t.id === tenant.id)
    const newChecked = [...checked]

    if (currentIndex === -1) {
      newChecked.push(tenant)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setChecked(newChecked)
  }

  const numberOfChecked = (items: readonly Tenant[]) =>
    intersection(checked, items).length

  const handleToggleAll = (items: readonly Tenant[]) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items))
    } else {
      setChecked(union(checked, items))
    }
  }

  const handleCheckedAvailableTenants = () => {
    setAvailableTenants([...availableTenants, ...employeeTenantsChecked])
    setEmployeeTenants(not(employeeTenants, employeeTenantsChecked))
    setChecked(not(checked, employeeTenantsChecked))
  }

  const handleCheckedEmployeeTenants = () => {
    setEmployeeTenants([...employeeTenants, ...availableTenantsChecked])
    setAvailableTenants(not(availableTenants, availableTenantsChecked))
    setChecked(not(checked, availableTenantsChecked))
  }

  const customList = (title: React.ReactNode, items: readonly Tenant[]) => (
    <Card>
      <CardHeader
        sx={{ px: 2, py: 1 }}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={numberOfChecked(items) === items.length && items.length !== 0}
            indeterminate={
              numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{
              'aria-label': 'todos los perfiles seleccionados',
            }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} seleccionados`}
      />
      <Divider />
      <List
        sx={{
          width:'auto',
          height: 'auto',
          bgcolor: 'background.paper',
          overflow: 'auto',
        }}
        dense
        component="div"
        role="list"
      >
        {items.map((tenant) => {
          const labelId = `transfer-list-item-${tenant.id}-label`

          return (
            <ListItemButton
              key={tenant.id}
              role="listitem"
              onClick={handleToggle(tenant)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.some((t) => t.id === tenant.id)}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    'aria-labelledby': labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={tenant.name} />
            </ListItemButton>
          )
        })}
      </List>
    </Card>
  )  

  const columns: GridColDef<EmployeePaymentType>[] = [
    {
      headerName: 'Tipo',
      field: 'paymentTypeDescription',
      minWidth: 125,
      flex: 1,
    },
    {
      headerName: 'En uso',
      field: 'isDeleted',
      valueGetter: (value, row) => (row.isDefault ? 'Si' : 'No'),
      minWidth: 120,
      flex: 1,
      renderCell: (params) => (
        <span
          style={{
            color: params.value === 'No' ? '#B71D18' : '#118D57',
            backgroundColor:
              params.value === 'No'
                ? 'rgba(255, 86, 48, 0.16)'
                : 'rgba(34, 197, 94, 0.16)',
            padding: '5px',
            borderRadius: '3px',
          }}
        >
          {params.value}
        </span>
      ),
    },
    {
      headerName: 'Detalle',
      field: 'accountNumber',       
      minWidth: accountNumberMaxLength * 10,   
      flex: 1, 
      valueGetter: (value, row) => row.employeeBankAccount?.accountNumber || '',     
      renderCell: (
        params // Text wrapping
      ) => (
        <div style={{ whiteSpace: 'normal', lineHeight: 'normal' }}>
          {params.value}
        </div>
      ),
    },    
    {
      headerName: 'Propietario',
      field: 'ownerName',
      minWidth: 143,      
      flex: 1,
      valueGetter: (value, row) => row.employeeBankAccount?.ownerName || '',
      renderCell: (
        params // Text wrapping
      ) => (
        <div style={{ whiteSpace: 'normal', lineHeight: 'normal' }}>
          {params.value}
        </div>
      ),
    }, 
    {
      field: 'actions',
      type: 'actions',
      headerName: '',
      getActions: (params: GridRowParams<EmployeePaymentType>) => [      
        <GridActionsCellItem
          sx={{ p: 0, m: 0 }}
          icon={<EditIcon />}
          label='Editar'
          className='textPrimary'
          onClick={() => handleEditRow(params.row)}
          color='inherit'
        />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label='Eliminar'
          onClick={() => handleDeletePaymentType(params.id)}
          color='inherit'
        />,
      ],
    },
  ]
  
  return (
    <Container>
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        mb={5}
      >
        <Grid container sx={{ width: '100%' }}>
          <Grid
            size={12}
            sx={{
              mb: 2,
              display: 'flex',
              justifyContent: 'flex-start',
            }}
          >
            <Button
              onClick={() => {
                navigate(-1)
              }}
              variant='contained'
              color='inherit'
              startIcon={<Iconify icon='eva:arrow-back-fill' />}
            >
              Regresar
            </Button>
          </Grid>
          <Grid size={{ xs: 12, md: 8 }}>
            <Typography variant='h4'>Editar empleado</Typography>
          </Grid>
        </Grid>
      </Stack>

      <Grid container sx={{ width: '100%' }}>
        {isLoading && <Loading centered={true} />}
        <Grid size={{ xs: 12, md: 3.8 }} sx={{ mr: { md: 2 }, mb: { xs: 3 } }}>
          <Card>
            <CardContent>
              <Box
                display='flex'
                flexDirection='column'
                alignItems='center'
                sx={{ mt: 2 }}
              >
                <Avatar
                  src={selectedImage || employee?.imageUrl}
                  alt='User Avatar'
                  sx={{ width: 180, height: 180, mb: 2 }}
                />
                <input
                  accept='image/*'
                  type='file'
                  id='image-upload'
                  style={{ display: 'none' }}
                  onChange={handleImageChange}
                />
                <label htmlFor='image-upload'>
                  <Button variant='contained' color='inherit' component='span'>
                    Actualizar imagen
                  </Button>
                </label>
              </Box>
              <Box
                display='flex'
                justifyContent='center'
                width='100%'
                sx={{ mt: 2 }}
              ></Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid size={{ xs: 12, md: 8 }}>
          <form autoComplete='off' noValidate onSubmit={handleSubmit}>
            <Card>
              <CardContent>
                <Grid container spacing={3}>
                  <Grid size={{ xs: 12, md: 6 }}>
                    <TextField
                      fullWidth
                      label='Identificación'
                      value={idDocument}
                      onChange={(e) => setIdDocument(e.target.value)}
                      required
                    />
                  </Grid>
                  <Grid size={{ xs: 12, md: 6 }}>
                    <DatePicker
                      format='DD/MM/YYYY'
                      label='Fecha de nacimiento'
                      value={birthDate}
                      onChange={handleDateChange}
                      sx={{ width: '100%' }}
                      onError={(newError) => setDateError(newError)}
                      slotProps={{
                        textField: {
                          error: isDateEmpty || dateError != null,
                          helperText: dateErrorMessage,
                        },
                      }}
                      minDate={minDate}
                      maxDate={maxDate}
                    />
                  </Grid>
                  <Grid size={{ xs: 12, md: 6 }}>
                    <TextField
                      fullWidth
                      label='Nombre'
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      required
                    />
                  </Grid>
                  <Grid size={{ xs: 12, md: 6 }}>
                    <TextField
                      fullWidth
                      label='Apellidos'
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      required
                    />
                  </Grid>
                  <Grid size={{ xs: 12, md: 6 }}>
                    <FormControl fullWidth>
                      <InputLabel id='gender-label'>Género</InputLabel>
                      <Select
                        labelId='gender-label'
                        value={gender}
                        label='Género'
                        onChange={(e) =>
                          setGender(e.target.value as EmployeeGender)
                        }
                        required
                      >
                        {Object.values(EmployeeGender).map((gender) => (
                          <MenuItem key={gender} value={gender}>
                            {getGenderDescription(gender)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid size={{ xs: 12, md: 6 }}>
                    <TextField
                      fullWidth
                      label='Dirección'
                      name='address'
                      onChange={(e) => setAddress(e.target.value)}
                      required
                      value={address}
                      multiline
                      maxRows={3}
                    />
                  </Grid>
                  <Grid size={12} container>
                    <Divider style={{ width: '100%' }} />
                  </Grid>
                  <Typography variant='h6'>Perfiles del empleado</Typography>
                  <Grid
                    container
                    spacing={2}
                    sx={{ justifyContent: 'center', alignItems: 'flex-start' }}
                  >
                    <Grid sx={{ alignSelf: 'flex-start' }}>
                      {customList('Perfiles asignados', employeeTenants)}
                    </Grid>

                    <Grid
                      size={{ xs: 12, md: 'auto' }}
                      sx={{ alignSelf: 'center' }}
                    >
                      <Grid
                        container
                        direction='column'
                        sx={{ alignItems: 'center' }}
                      >
                        <Tooltip title='Remover perfil' enterTouchDelay={0}>
                          <span>
                            <IconButton
                              color='error'
                              onClick={handleCheckedAvailableTenants}
                              disabled={employeeTenantsChecked.length === 0}
                              aria-label='mover seleccionados a la derecha'
                            >
                              <ArrowCircleRightIcon />
                            </IconButton>
                          </span>
                        </Tooltip>
                        <Tooltip title='Agregar perfil' enterTouchDelay={0}>
                          <span>
                            <IconButton
                              color='success'
                              onClick={handleCheckedEmployeeTenants}
                              disabled={availableTenantsChecked.length === 0}
                              aria-label='mover seleccionados a la izquierda'
                            >
                              <ArrowCircleLeftIcon />
                            </IconButton>
                          </span>
                        </Tooltip>
                      </Grid>
                    </Grid>
                    <Grid sx={{ alignSelf: 'flex-start' }}>
                      {customList('Perfiles no asignados', availableTenants)}
                    </Grid>
                  </Grid>

                  <Grid size={12} container>
                    <Divider style={{ width: '100%' }} />
                  </Grid>
                  <Grid container spacing={0} sx={{ width: '100%' }}>
                    <Grid size={{ xs: 12, md: 8 }}>
                      <Typography variant='h6'>Métodos de pago</Typography>
                    </Grid>
                    <Grid
                      size={{ xs: 12, md: 4 }}
                      sx={{
                        display: 'flex',
                        justifyContent: { xs: 'flex-start', md: 'flex-end' },
                      }}
                    >
                      <Button
                        onClick={() => setOpenAddPaymentTypeDialog(true)}
                        variant='contained'
                        color='inherit'
                        startIcon={<Iconify icon='eva:plus-fill' />}
                      >
                        Agregar
                      </Button>
                    </Grid>
                  </Grid>

                  <Grid size={12}>
                    <DataGrid
                      rows={employeePaymentTypes || []}
                      columns={columns}
                      disableColumnFilter
                      disableColumnSelector
                      disableDensitySelector
                      disableRowSelectionOnClick
                      density='compact'
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <CardActions
                sx={{ justifyContent: 'flex-end', mt: 2, mr: 1, mb: 1 }}
              >
                <Button variant='contained' color='inherit'>
                  Guardar
                </Button>
              </CardActions>
            </Card>
          </form>
        </Grid>
      </Grid>
      <DynamicDialog
        openDialog={openAddPaymentTypeDialog}
        dialogTitle={
          editMode ? 'Editar Método de Pago' : 'Agregar Método de Pago'
        }
        onCloseAction={() => {
          setOpenAddPaymentTypeDialog(false)
          setEditMode(false)
          setSelectedPaymentType(null)
        }}
        buttonAccept={{
          text: 'Aceptar',
          action: () => setOpenAddPaymentTypeDialog(false),
        }}
        buttonCancel={{
          text: 'Cancelar',
          action: () => setOpenAddPaymentTypeDialog(false),
        }}
      >
        <AddEmployeePaymentTypeDialog
          open={openAddPaymentTypeDialog}
          onClose={() => {
            setOpenAddPaymentTypeDialog(false)
            setEditMode(false)
            setSelectedPaymentType(null)
          }}
          onAdd={editMode ? handleEditPaymentType : handleAddPaymentType}
          editMode={editMode}
          paymentType={selectedPaymentType}
        />
      </DynamicDialog>
    </Container>
  )
}

export default EmployeeEditView
