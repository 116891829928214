import MoreVertIcon from '@mui/icons-material/MoreVert'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid2'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { AxiosError } from 'axios'
import { Dayjs } from 'dayjs'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { axiosPrivate } from '../../api/axios'
import { endpoints } from '../../api/constants'
import { Tenant } from '../../context/AuthProvider'
import { usePayrollPay } from '../../context/PayrollPayProvider'
import { useSnackbar } from '../../context/SnackbarProvider'
import { getDisplayName, PaymentType } from '../../types/Employee'
import { Farm } from '../../types/Farm'
import {
    EmployeeWorkDetailPayroll,
    GetPayrollDetailsResponse,
    PayrollDetailsRequest,
} from '../../types/Payroll'
import { fNumber } from '../../utils/format-number'
import { fDateShort } from '../../utils/format-time'
import PayrollEmployeeWorkDetails from './PayrollEmployeeWorkDetails'
import PayrollFilters from './PayrollFilters'
import ReportSkeletons from './ReportSkeletons'

const MIN_LOADING_TIME = 500

interface PayrollPayNotIncludedProps {
  payrollId: number
}

type MenuOption = {
  name: string
  isEnable: boolean
  action: () => void
}

const PayrollPayNotIncluded: FC<PayrollPayNotIncludedProps> = ({
  payrollId,
}) => {
  const { showError } = useSnackbar()
  const [payrolDetails, setPayrolDetails] =
    useState<GetPayrollDetailsResponse>()
  const [selectedTenant, setSelectedTenant] = useState<Tenant | null>(null)
  const [selectedFarm, setSelectedFarm] = useState<Farm | null>(null)
  const [selectedJobTypeIds, setSelectedJobTypeIds] = useState<number[]>([])
  const [selectedPaymentType, setSelectedPaymentType] =
    useState<PaymentType | null>(null)
  const [selectedFromDate, setSelectedFromDate] = useState<Dayjs | null>(null)
  const [selectedToDate, setSelectedToDate] = useState<Dayjs | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [openPayrollDetailPopup, setOpenPayrollDetailPopup] = useState(false)
  const [selectedEmployeeWorkDetail, setSelectedEmployeeWorkDetail] = useState<EmployeeWorkDetailPayroll | null>(null)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const openPayrollDetailMenu = Boolean(anchorEl)
  const { payrollUpdated } = usePayrollPay()  
  const navigate = useNavigate()
  
  const payrollDetailsRequest: PayrollDetailsRequest = useMemo(
    () => ({      
      tenantId: selectedTenant ? selectedTenant.id : null,
      farmId: selectedFarm ? selectedFarm.id : null,
      employeeJobTypeIds: selectedJobTypeIds,
      paymentTypeId: selectedPaymentType ? selectedPaymentType.id : null,
      payrollMasterId: null,
      fromDate: selectedFromDate ? selectedFromDate.format('YYYY-MM-DD') : null,
      toDate: selectedToDate ? selectedToDate.format('YYYY-MM-DD') : null,
    }),
    [
      selectedTenant,
      selectedFarm,
      selectedJobTypeIds,
      selectedPaymentType,
      selectedFromDate,
      selectedToDate,
    ]
  )

  const payrollDetailMenuOptions: MenuOption[] = [
    {
      name: 'Editar',
      isEnable: true,
      action: () => {
        setOpenPayrollDetailPopup(true)
      },
    },
  ]

  const getJournalDailyWorkReports = useCallback(
    async (controller: AbortController) => {
      setIsLoading(true)
      try {
        const fetchStart = Date.now()

        const responsePromise = axiosPrivate.post(
          endpoints.getPayrollDetails,
          payrollDetailsRequest,
          { signal: controller.signal }
        )

        const [response] = await Promise.all([
          responsePromise,
          new Promise((resolve) =>
            setTimeout(resolve, MIN_LOADING_TIME - (Date.now() - fetchStart))
          ), // Ensure a minimum loading time
        ])

        const payrollResponse = response.data as GetPayrollDetailsResponse
        setPayrolDetails(payrollResponse)
        setIsLoading(false)
      } catch (err: any) {
        const error = err as AxiosError
        if (error.name !== 'CanceledError') {
          setIsLoading(false)
          showError('Error al obtener los datos de la planilla')
        }
      }
    },
    [payrollDetailsRequest, showError]
  )

  useEffect(() => {
    const controller = new AbortController()
    getJournalDailyWorkReports(controller)
    return () => {
      controller.abort()
    }
  }, [
    selectedTenant,
    selectedFarm,
    selectedJobTypeIds,
    selectedPaymentType,
    getJournalDailyWorkReports,
    payrollUpdated
  ])

  const handleClickPayrollDetailMenu = (event: React.MouseEvent<HTMLElement>, employeeWorkDetail: EmployeeWorkDetailPayroll) => {
    setAnchorEl(event.currentTarget)
    setSelectedEmployeeWorkDetail(employeeWorkDetail)    
  }

  const handleCloseReportMenu = () => {
    setAnchorEl(null)
  }

  const onClosePayrollEmployeeWorkDetailsDialog = () => {    
    setOpenPayrollDetailPopup(false)
  }

  const handleDateClick = (journalDailyWorkId: number) => {
    navigate(`/reporte-trabajo/detalles/${journalDailyWorkId}`, {
      state: { currentJournalDailyWorkId: journalDailyWorkId },
    })    
  }

  return (
    <>
      {payrolDetails?.employeeWorkDetails.length === 0 ? (
        <Typography variant='h6' sx={{ ml: 2, mt: 3 }}>
          No hay reportes no incluidos en la planilla.
        </Typography>
      ) : (
        <>
          <PayrollFilters
            tenants={payrolDetails?.tenants || []}
            selectedTenant={selectedTenant}
            setSelectedTenant={setSelectedTenant}
            farms={payrolDetails?.farms || []}
            selectedFarm={selectedFarm}
            setSelectedFarm={setSelectedFarm}
            employeeJobTypes={payrolDetails?.employeeJobTypes || []}
            selectedJobTypeIds={selectedJobTypeIds}
            setSelectedJobTypeIds={setSelectedJobTypeIds}
            selectedPaymentType={selectedPaymentType}
            setSelectedPaymentType={setSelectedPaymentType}
            selectedFromDate={selectedFromDate}
            onFromDateChange={setSelectedFromDate}
            selectedToDate={selectedToDate}
            onToDateChange={setSelectedToDate}
          />

          <Card>
            <CardContent>
              {isLoading ? (
                <ReportSkeletons count={5} />
              ) : (
                payrolDetails?.employeeWorkDetails.map((report, index) => (
                  <Grid
                    size={12}
                    container
                    spacing={1}
                    sx={{ ml: 1 }}
                    key={index}
                  >
                    <Grid size={12}>
                      <Grid size={12} container>
                        <Grid size={11}>
                          <Typography variant='h6' sx={{ ml: 1 }}>
                            {getDisplayName(report.employee)}
                          </Typography>
                        </Grid>

                        <Grid
                          container
                          size={1}
                          justifyContent='flex-end'
                          alignItems='center'
                        >
                          <IconButton
                            aria-label='more'
                            id='long-button'
                            size='small'
                            aria-controls={
                              openPayrollDetailMenu ? 'long-menu' : undefined
                            }
                            aria-expanded={
                              openPayrollDetailMenu ? 'true' : undefined
                            }
                            aria-haspopup='true'
                            onClick={(e) =>
                              handleClickPayrollDetailMenu(e, report)
                            }
                          >
                            <MoreVertIcon />
                          </IconButton>
                        </Grid>
                        <Menu
                          id='long-menu'
                          anchorEl={anchorEl}
                          open={openPayrollDetailMenu}
                          onClose={handleCloseReportMenu}
                          MenuListProps={{
                            'aria-labelledby': 'long-button',
                          }}
                        >
                          {payrollDetailMenuOptions.map((option) => (
                            <MenuItem
                              key={option.name}
                              onClick={() => {
                                handleCloseReportMenu()
                                if (
                                  selectedEmployeeWorkDetail !== null &&
                                  option.isEnable
                                ) {
                                  option.action()
                                }
                              }}
                              disabled={!option.isEnable}
                            >
                              {option.name}
                            </MenuItem>
                          ))}
                        </Menu>
                      </Grid>

                      <TableContainer component={Paper}>
                        <Table
                          sx={{ minWidth: { xs: 'auto', md: 650 } }}
                          size='small'
                          aria-label='a dense table'
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell>Fecha</TableCell>
                              <TableCell>Trabajo</TableCell>
                              <TableCell align='right'>Cantidad</TableCell>
                              <TableCell align='right'>Precio</TableCell>
                              <TableCell align='right'>SubTotal</TableCell>
                              <TableCell>Finca</TableCell>
                              <TableCell>Perfil</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {report.journalDailyWorkDetails.map((detail) => (
                              <TableRow
                                key={detail.id}
                                sx={{
                                  '&:last-child td, &:last-child th': {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell>
                                  <Button
                                    onClick={() => {
                                      handleDateClick(
                                        detail.journalDailyWork.id
                                      )
                                    }}
                                  >
                                    {fDateShort(
                                      detail.journalDailyWork
                                        .journalDailyWorkDate
                                    )}
                                  </Button>
                                </TableCell>
                                <TableCell>
                                  {detail.employeeJobType.name}
                                </TableCell>
                                <TableCell align='right'>
                                  {fNumber(detail.jobQuantity)}
                                </TableCell>
                                <TableCell align='right'>
                                  {fNumber(detail.jobPrice)}
                                </TableCell>
                                <TableCell align='right'>
                                  {fNumber(detail.dayPayment)}
                                </TableCell>
                                <TableCell>
                                  {detail.journalDailyWork.farm.name}
                                </TableCell>
                                <TableCell>
                                  {detail.journalDailyWork.tenant.name}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>

                      <Grid container spacing={1}>
                        <Grid size={{ xs: 12, md: 'auto' }}>
                          <Typography
                            variant='body2'
                            fontWeight='bold'
                            sx={{ mx: 1 }}
                          >
                            Total = {fNumber(report.totalPayment)}
                          </Typography>
                        </Grid>

                        <Grid size={{ xs: 12, md: 'auto' }}>
                          <Typography variant='body2' sx={{ mx: 1 }}>
                            Pago en {report.employee.paymentTypeDescription}{' '}
                            {report.accountNumber
                              ? `(${report.accountNumber})`
                              : ''}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid size={12} container sx={{ mx: 1, mt: 2, mb: 2 }}>
                        <Divider style={{ width: '100%' }} />
                      </Grid>
                    </Grid>
                  </Grid>
                ))
              )}

              <Grid size={12} container sx={{ mx: 1, mt: 3, mb: 2 }}>
                <Divider style={{ width: '100%' }} />
              </Grid>

              <Grid size={12} container spacing={1} sx={{ ml: 1 }}>
                <TextField
                  label='Total Planilla'
                  value={fNumber(payrolDetails?.totalPayrollPayment)}
                  fullWidth
                  InputProps={{
                    readOnly: true,
                    style: { fontWeight: 'bold' },
                  }}
                />
              </Grid>

              <Grid size={12} container spacing={1} sx={{ ml: 1, mt: 1 }}>
                {payrolDetails?.paymentTypeTotals.map((paymentTypeTotal) => (
                  <Grid
                    size={{ xs: 12, md: 6 }}
                    key={paymentTypeTotal.paymentType}
                    sx={{ mt: 1 }}
                  >
                    <TextField
                      label={`Total ${paymentTypeTotal.paymentType}`}
                      value={fNumber(paymentTypeTotal.totalPayment)}
                      fullWidth
                      InputProps={{
                        readOnly: true,
                        style: { fontWeight: 'bold' },
                      }}
                    />
                  </Grid>
                ))}
              </Grid>

              <Grid size={12} container sx={{ mx: 1, mt: 1, mb: 2 }}>
                <Divider style={{ width: '100%' }} />
              </Grid>
              <PayrollEmployeeWorkDetails
                open={openPayrollDetailPopup}
                onClose={onClosePayrollEmployeeWorkDetailsDialog}
                employeeWorkDetails={selectedEmployeeWorkDetail!}
                isDeleteAction={false}
                payrollId={payrollId}
              />
            </CardContent>
          </Card>
        </>
      )}
    </>
  )
}

export default PayrollPayNotIncluded
