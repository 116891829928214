import { Button } from "@mui/material"
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import Typography from "@mui/material/Typography"
import { AxiosError } from 'axios'
import { FC, useEffect, useRef, useState } from "react"
import { useNavigate } from 'react-router-dom'
import { ApiError, axiosPrivate } from '../../api/axios'
import { endpoints } from '../../api/constants'
import Loading from '../../components/Loading/Loading'
import { useAuth } from '../../context/AuthProvider'
import { useDailyWorkReport } from '../../context/DailyWorkReportProvider'
import { useSnackbar } from '../../context/SnackbarProvider'
import DailyWorkReportSteps from "./DailyWorkReportSteps"

type JournalDailyWorkDetailsModel = {
  id: number
  employeeId: number
  employeeJobTypeId: number
  jobQuantity: number
}

type JournalDailyWorkProductDetailModel = {
  id: number
  productId: number
  productQuantity: number    
}

type UpdateJournalDailyWorkRequest = {
  id: number
  updatedByUserEmail: string
  farmId: number
  tenantId: number
  journalDailyWorkDate: string
  description: string | undefined  
  journalDailyWorkDetails: JournalDailyWorkDetailsModel[]
  journalDailyWorkJobTypesIds: number[]
  journalDailyWorkProducts: JournalDailyWorkProductDetailModel[]
}

interface EditDailyWorkReportViewProps {
  journalDailyWorkId: number
}

const EditDailyWorkReportView: FC<EditDailyWorkReportViewProps> = ({
  journalDailyWorkId
}) => {
  const { auth } = useAuth()
    const { showSuccess, showError, showWarning } = useSnackbar()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [activeStep, setActiveStep] = useState(0)
    const [displayCancelButton, setDisplayCancelButton] = useState<boolean>(true)
    const {
      selectedFarm,
      selectedFarmJobTypes,
      selectedDate,
      observations,
      journalDailyWorkProducts,
      journalDailyWorkDetails,
    } = useDailyWorkReport()    
    const existingWorkDetailIdsRef = useRef<number[]>([])
    const existingProductDetailIdsRef = useRef<number[]>([])
    const navigate = useNavigate()

    useEffect(() => {
      let isMounted = true
      const controller = new AbortController()

      const getPayrollForJournal = async () => {
        try {
          setIsLoading(true)
          const response = await axiosPrivate.get(
            `${endpoints.getPayrollMasterForJournalDailyWork}${journalDailyWorkId}`
          )
          setIsLoading(false)
          const payroll = response?.data
          if(payroll){
            showWarning(
              'Atención: Este reporte está asociado a una planilla de pago.\n' +
              '- Si actualiza el reporte, la planilla de pago también será actualizada.\n' +
              '- Si el reporte ya fue pagado para un empleado entonces no será actualizado.\n',
              false
            )
          }
         
        } catch (err: any) {
          const error = err as AxiosError<ApiError>
          if (error.name !== 'CanceledError') {
            showError('Error consultar la planilla ' + error?.response?.data?.detail)
          }
          setIsLoading(false)
        }
    }
  
      if (isMounted) {
        getPayrollForJournal()
      }
  
      return () => {
        isMounted = false
        controller.abort()
      }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])     

    useEffect(() => {
      existingWorkDetailIdsRef.current = journalDailyWorkDetails.map(detail => detail.id)
      existingProductDetailIdsRef.current = journalDailyWorkProducts.map(detail => detail.id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function updatedReport() {
        setIsLoading(true)
        setDisplayCancelButton(false)
        const controller = new AbortController()
    
        const updateJournalDailyWorkRequest: UpdateJournalDailyWorkRequest = {
          id: journalDailyWorkId,
          updatedByUserEmail: auth.user?.email || '', //TODO: Validate user
          farmId: selectedFarm!.id,
          tenantId: auth.selectedTenant?.id || 0,
          journalDailyWorkDate: selectedDate!.utc().toISOString(),
          description: observations,          
          journalDailyWorkDetails: journalDailyWorkDetails.map(detail => {

            const detailId = existingWorkDetailIdsRef.current.find(id => id === detail.id)         

            return {
              id: detailId || 0,
              employeeId: detail.employee!.id,
              employeeJobTypeId: detail.employeeJobType!.id,
              jobQuantity: Number(detail.jobQuantity)
            }
          }),
          journalDailyWorkJobTypesIds: selectedFarmJobTypes.map(jobType => jobType.id),
          journalDailyWorkProducts: journalDailyWorkProducts.map(detail => {

            const productDetailId = existingProductDetailIdsRef.current.find(id => id === detail.id)

            return {
              id: productDetailId || 0,
              productId: detail.productInventory!.id,
              productQuantity: detail.productQuantity
            }
          })
        }
    
        const updateJournalReport = async () => {          
          try {
            const response = await axiosPrivate.put(
              endpoints.editJournalDailyWork,
              updateJournalDailyWorkRequest,
              { signal: controller.signal }
            )
    
            if (response.status === 200) {
              showSuccess('Reporte actualizado exitosamente!')
              setActiveStep((prevActiveStep) => prevActiveStep + 1)                  
            }
            
          } catch (err: any) {
            const error = err as AxiosError<ApiError>
    
            if (error.name !== 'CanceledError') {
              showError('Error al actualizar el reporte ' + error?.response?.data?.detail)
            }
          }
          finally {
            setIsLoading(false)
          }
        }
    
        updateJournalReport()    
    }
    
    return (
      <Container>
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
          mb={5}
        >
          <Typography variant='h4'>Actualizar reporte de trabajo</Typography>
        </Stack>

        <DailyWorkReportSteps
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          lastStepButtonLabel='Actualizar reporte'
          successMessage='Reporte actualizado!'
          onSubmit={updatedReport}
        />
        {displayCancelButton && 
        <Button
          sx={{ mt: 6, ml: 6 }}
          variant='contained'
          color='error'
          onClick={() => navigate(-1)}
        >
          Cancelar
        </Button>}
        {isLoading && <Loading centered={true} />}
      </Container>
    )
}

export default EditDailyWorkReportView