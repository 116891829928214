import { Tenant } from "../context/AuthProvider"

export type Employee = {
  id: number
  firstName: string
  lastName: string
  paymentTypeDescription: string
}

export function getDisplayName(employee: Employee) {
  const lastName = employee.lastName.split(' ')[0];
  return `${employee.firstName} ${lastName}`;
}

export function getFullName(employee: Employee) {
  return `${employee.firstName} ${employee.lastName}`
}

export type PaymentType = {
  id: number
  name: string
}
export type Bank = {
  id: number
  name: string
}

export type EmployeeBankAccount = {
  id: number
  bank?: Bank
  accountNumber: string  
  ownerName?: string
  ownerIdDocument?: string
}

export type EmployeePaymentType = {
  id: number
  paymentTypeId: number
  paymentTypeDescription: string 
  isDefault: boolean
  employeeBankAccount: EmployeeBankAccount
}

export type EmployeeInformation = {
  id: number
  idDocument: string
  firstName: string
  lastName: string
  paymentTypeDescription: string  
  isDeleted: boolean
  tenants: Tenant[]
}

export type EmployeeDetails = {
  id: number
  idDocument: string
  birthDate: string
  firstName: string
  lastName: string
  paymentTypeDescription: string
  address: string
  phoneNumber?: string
  gender: string
  isDeleted: boolean
  imageUrl?: string
  createdDate: string
  modifiedDate: string
  modifiedByUserEmail: string
  tenants: Tenant[]
  employeePaymentTypes: EmployeePaymentType[]
}

export enum EmployeeStatus {
  Active = 1,
  Inactive = 2,
  All = 3
}

export enum PaymentTypeEnum {
  None = 0,
  Cash = 1,
  Deposit = 2,
  BankCard = 3,
  Sinpe = 4
}

export function getJournalDailyWorkPaymentStatusLabel(
  status: EmployeeStatus
): string {
  switch (status) {
    case EmployeeStatus.Active:
      return 'Activo'
    case EmployeeStatus.Inactive:
      return 'Desactivado'
    case EmployeeStatus.All:
      return 'Todo'
    default:
      return 'Desconocido'
  }
}

export enum EmployeeGender {
  M = 'M',
  F = 'F'
}

export function getGenderDescription(gender: string): string {
  switch (gender) {
    case 'M':
      return "Masculino";
    case 'F':
      return "Femenino";
    default:
      return "Desconocido";
  }
}