import HomeIcon from '@mui/icons-material/Home'
import AutoStoriesIcon from '@mui/icons-material/AutoStories'
import OilBarrelIcon from '@mui/icons-material/OilBarrel'
import RequestQuoteIcon from '@mui/icons-material/RequestQuote'
import CalculateIcon from '@mui/icons-material/Calculate'
import PriceCheckIcon from '@mui/icons-material/PriceCheck'
import PeopleIcon from '@mui/icons-material/People'
import { ReactNode } from 'react'

export enum MenuType {
  Item = 'item',
  Submenu = 'submenu'
}

export interface MenuItem {
  type: MenuType.Item
  title: string
  path: string
  icon: ReactNode
}

export interface MenuWithSubmenu {
  type: MenuType.Submenu
  title: string
  icon: ReactNode
  children: MenuItem[]
}

export type MenuConfig = MenuItem | MenuWithSubmenu

const navConfig: MenuConfig[] = [
  {
    type: MenuType.Item,
    title: 'Inicio',
    path: '/',
    icon: <HomeIcon />,
  },
  {
    type: MenuType.Item,
    title: 'Reporte Trabajo',
    path: '/reporte-trabajo',
    icon: <AutoStoriesIcon />,
  },
  {
    type: MenuType.Submenu,
    title: 'Planilla',
    icon: <RequestQuoteIcon />,
    children: [
      {
        type: MenuType.Item,
        title: 'Calcular y Pagar Planilla',
        path: '/planilla/calcular-pagar',
        icon: <CalculateIcon />,
      },
      {
        type: MenuType.Item,
        title: 'Planillas Pagadas',
        path: '/planilla',
        icon: <PriceCheckIcon />,
      },
    ],
  },
  {
    type: MenuType.Item,
    title: 'Inventario Productos',
    path: '/inventario-productos',
    icon: <OilBarrelIcon />,
  },
  {
    type: MenuType.Item,
    title: 'Empleados',
    path: '/empleados',
    icon: <PeopleIcon />,
  },
]

export default navConfig