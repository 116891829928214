import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Autocomplete from '@mui/material/Autocomplete'
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid2'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { AxiosError } from 'axios'
import { FC, useEffect, useState } from 'react'
import { axiosPrivate } from '../../api/axios'
import { endpoints } from '../../api/constants'
import { Tenant } from '../../context/AuthProvider'
import { useSnackbar } from '../../context/SnackbarProvider'
import {
    EmployeeStatus,
    getJournalDailyWorkPaymentStatusLabel,
    PaymentType,
} from '../../types/Employee'

interface EmployeeFiltersProps {
  selectedEmployeeStatus: EmployeeStatus
  onEmployeeStatusChange: (newStatus: EmployeeStatus) => void
  selectedTenant: Tenant | null
  setSelectedTenant: (tenant: Tenant | null) => void
  selectedPaymentType: PaymentType | null
  setSelectedPaymentType: (paymentType: PaymentType | null) => void
}

const EmployeeFilters: FC<EmployeeFiltersProps> = ({
  selectedEmployeeStatus,
  onEmployeeStatusChange,
  selectedTenant,
  setSelectedTenant,
  selectedPaymentType,
  setSelectedPaymentType,
}) => {
  const [tenants, setTenants] = useState<Tenant[]>([])
  const selectedEmployeeStatusString = selectedEmployeeStatus.toString()
  const { showError } = useSnackbar()
  const [paymentTypes, setPaymentTypes] = useState<PaymentType[]>([])

  const handleEmployeeStatusChange = (event: SelectChangeEvent) => {
    const newStatus = parseInt(event.target.value, 10)
    onEmployeeStatusChange(newStatus)
  }

  const handleTenantChange = (event: any, newValue: Tenant | null) => {
    if (newValue?.id !== selectedTenant?.id) {
      setSelectedTenant(newValue)
    }
  }

  const handlePaymentTypeChange = (
    event: any,
    newValue: PaymentType | null
  ) => {
    setSelectedPaymentType(newValue)
  }

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()

    const getTenants = async () => {
      try {
        const response = await axiosPrivate.get(endpoints.getTenants, {
          signal: controller.signal,
        })
        if (isMounted) {
          setTenants(response.data)
        }
      } catch (err: any) {
        const error = err as AxiosError
        if (error.name !== 'CanceledError') {
          showError('Error al cargar los perfiles')
        }
      }
    }

    const getPaymentTypes = async () => {
      try {
        const response = await axiosPrivate.get(endpoints.getPaymentTypes, {
          signal: controller.signal,
        })
        if (isMounted) {
          setPaymentTypes(response.data)
        }
      } catch (err: any) {
        const error = err as AxiosError
        if (error.name !== 'CanceledError') {
          showError('Error al cargar los tipos de pago')
        }
      }
    }

    getTenants()
    getPaymentTypes()

    return () => {
      isMounted = false
      controller.abort()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div style={{ marginBottom: '40px' }}>
      <Accordion defaultExpanded={true} sx={{ borderRadius: '12px' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1-content'
          id='panel1-header'
          sx={{
            padding: '1',
            minHeight: '0 !important',
            '& .MuiAccordionSummary-content': { margin: '8px 0' },
          }}
        >
          <Typography
            fontWeight='bold'
            sx={{ fontSize: '0.900rem', lineHeight: '1.2' }}
          >
            Filtrar
          </Typography>
        </AccordionSummary>

        <AccordionDetails>
          <Grid container spacing={0.7} sx={{ my: 0, mx: 1, p: 0 }}>
            <Grid size={{ xs: 12, md: 4 }}>
              <FormControl
                sx={{
                  minWidth: 200,
                  ml: { xs: 0, md: 2 },
                  mt: { xs: 1, md: 0 },
                }}
                size='small'
                fullWidth
              >
                <InputLabel id='estado-empleado-select-label'>
                  Estado
                </InputLabel>
                <Select
                  labelId='estado-empleado-select-label'
                  id='estado-empleado-select'
                  value={selectedEmployeeStatusString}
                  label='Estado'
                  onChange={handleEmployeeStatusChange}
                >
                  <MenuItem value={EmployeeStatus.Active.toString()}>
                    {getJournalDailyWorkPaymentStatusLabel(
                      EmployeeStatus.Active
                    )}
                  </MenuItem>
                  <MenuItem value={EmployeeStatus.Inactive.toString()}>
                    {getJournalDailyWorkPaymentStatusLabel(
                      EmployeeStatus.Inactive
                    )}
                  </MenuItem>
                  <MenuItem value={EmployeeStatus.All.toString()}>
                    {getJournalDailyWorkPaymentStatusLabel(EmployeeStatus.All)}
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid size={{ xs: 12, md: 4 }}>
              <Autocomplete
                size='small'
                sx={{
                  minWidth: 200,
                  ml: { xs: 0, md: 2 },
                  mt: { xs: 1, md: 0 },
                }}
                fullWidth
                disablePortal
                id='combo-box-perfil'
                options={tenants}
                getOptionLabel={(tenant) => tenant.name}
                isOptionEqualToValue={(option: Tenant, value: Tenant) =>
                  option.id === value.id
                }
                value={selectedTenant}
                onChange={handleTenantChange}
                renderInput={(params) => (
                  <TextField {...params} label='Perfil' />
                )}
              />
            </Grid>
            <Grid size={{ xs: 12, md: 4 }}>
              <Autocomplete
                size='small'
                sx={{
                  minWidth: 200,
                  ml: { xs: 0, md: 2 },
                  mt: { xs: 1, md: 0 },
                }}
                fullWidth
                disablePortal
                id='combo-box-tipo-pago'
                options={paymentTypes}
                getOptionLabel={(payment) => payment.name}
                isOptionEqualToValue={(
                  option: PaymentType,
                  value: PaymentType
                ) => option.id === value.id}
                value={selectedPaymentType}
                onChange={handlePaymentTypeChange}
                renderInput={(params) => (
                  <TextField {...params} label='Tipo de pago' />
                )}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

export default EmployeeFilters
