import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@mui/material'
import Grid from '@mui/material/Grid2'
import Switch from '@mui/material/Switch'
import { AxiosError } from 'axios'
import { useEffect, useState } from 'react'
import { axiosPrivate } from '../../api/axios'
import { endpoints } from '../../api/constants'
import { useSnackbar } from '../../context/SnackbarProvider'
import {
    Bank,
    EmployeePaymentType,
    PaymentType,
    PaymentTypeEnum,
} from '../../types/Employee'

type Props = {
    open: boolean
    onClose: () => void
    onAdd: (paymentType: EmployeePaymentType) => void
    editMode?: boolean
    paymentType?: EmployeePaymentType | null
  }

const AddEmployeePaymentTypeDialog: React.FC<Props> = ({
    open,
    onClose,
    onAdd,
    editMode = false,
    paymentType = null,
}) => {
  const { showError } = useSnackbar()
  const [paymentTypes, setPaymentTypes] = useState<PaymentType[]>([])
  const [selectedPaymentType, setSelectedPaymentType] = useState<PaymentType | null>(null)
  const [accountNumber, setAccountNumber] = useState<string>('')
  const [selectedBank, setSelectedBank] = useState<Bank | undefined>(undefined)
  const [ownerName, setOwnerName] = useState<string>('')  
  const [detailsLabel, setDetailsLabel] = useState<string>('Detalles')
  const [showBank, setShowBank] = useState<boolean>(false)
  const [showAccountDetails, setShowAccountDetails] = useState<boolean>(false)
  const [banks, setBanks] = useState<Bank[]>([])
  const [isDefault, setIsDefault] = useState<boolean>(true)
  
  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()

    const getPaymentTypes = async () => {
      try {
        const response = await axiosPrivate.get(endpoints.getPaymentTypes, {
          signal: controller.signal,
        })
        if (isMounted) {
          const paymentTypesResponse = response.data as PaymentType[]
          setPaymentTypes(
            paymentTypesResponse.filter((p) => p.id !== PaymentTypeEnum.None)
          )
        }
      } catch (err: any) {
        const error = err as AxiosError
        if (error.name !== 'CanceledError') {
          showError('Error al cargar los tipos de pago')
        }
      }
    }

    const getBanks = async () => {
      try {
        const response = await axiosPrivate.get(endpoints.getBanks, {
          signal: controller.signal,
        })
        if (isMounted) {
          setBanks(response.data)
        }
      } catch (err: any) {
        const error = err as AxiosError
        if (error.name !== 'CanceledError') {
          showError('Error al cargar los bancos')
        }
      }
    }

    getBanks()
    getPaymentTypes()

    return () => {
      isMounted = false
      controller.abort()
    }
  }, [showError])

  useEffect(() => {
    if (editMode && paymentType) {
        setSelectedPaymentType({
            id: paymentType.paymentTypeId,
            name: paymentType.paymentTypeDescription,
          });
      setAccountNumber(paymentType.employeeBankAccount?.accountNumber || '');
      setSelectedBank(paymentType.employeeBankAccount?.bank);
      setOwnerName(paymentType.employeeBankAccount?.ownerName || '');
      setIsDefault(paymentType.isDefault);
    }
  }, [editMode, paymentType]);

  useEffect(() => {
    if (selectedPaymentType) {
      if (selectedPaymentType.id === PaymentTypeEnum.Deposit) {
        setDetailsLabel('Cuenta bancaria')
        setShowBank(true)
        setShowAccountDetails(true)
      } else if (selectedPaymentType.id === PaymentTypeEnum.BankCard) {
        setShowBank(true)
        setDetailsLabel('Número de tarjeta')
        setShowAccountDetails(true)
      } else if (selectedPaymentType.id === PaymentTypeEnum.Sinpe) {
        setShowBank(false)
        setDetailsLabel('Numero de teléfono')
        setShowAccountDetails(true)
      } else if (selectedPaymentType.id === PaymentTypeEnum.Cash) {
        setShowBank(false)        
        setShowAccountDetails(false)
      }
    }
  }, [selectedPaymentType])

  const handleAdd = () => {
    if (!selectedPaymentType) {
      showError('El tipo de pago es requerido')
      return
    }
    if (showBank && !selectedBank) {
      showError('El banco es requerido')
      return
    }
    if (showAccountDetails && !accountNumber) {
      showError(`${detailsLabel} es requerido`)
      return
    }
    if (showAccountDetails && !ownerName) {
      showError('El nombre del propietario es requerido')
      return
    }

    const newPaymentType: EmployeePaymentType = {
      id: Math.random(), // Generate a temporary ID
      paymentTypeId: selectedPaymentType.id,
      paymentTypeDescription: selectedPaymentType.name,
      isDefault: isDefault,
      employeeBankAccount: {
        id: Math.random(), // Generate a temporary ID
        bank: selectedBank,
        accountNumber,
        ownerName,
      },
    }
    onAdd(newPaymentType)
    onClose()
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth sx={{ minHeight: 500 }}>
      <DialogTitle>{editMode ? 'Editar método de pago' : 'Agregar método de pago'}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid size={12} sx={{ mt: 3 }}>
            <FormControl fullWidth>
              <InputLabel id='payment-type-label'>Tipo de pago</InputLabel>
              <Select
                labelId='payment-type-label'
                defaultValue={1}
                value={selectedPaymentType?.id || ''}
                label='Tipo de pago'
                onChange={(e) => {
                  const paymentType = paymentTypes.find(
                    (p) => p.id === e.target.value
                  )
                  setSelectedPaymentType(paymentType || null)
                }}
              >
                {paymentTypes.map((paymentType) => (
                  <MenuItem key={paymentType.id} value={paymentType.id}>
                    {paymentType.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {showBank && (
            <Grid size={12}>
              <FormControl fullWidth>
                <InputLabel id='bank-label'>Banco</InputLabel>
                <Select
                  labelId='bank-label'
                  value={selectedBank?.id || ''}
                  label='Banco'
                  onChange={(e) => {
                    const bank = banks.find((b) => b.id === e.target.value)
                    setSelectedBank(bank || undefined)
                  }}
                >
                  {banks.map((bank) => (
                    <MenuItem key={bank.id} value={bank.id}>
                      {bank.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
          {showAccountDetails && (
            <>
              <Grid size={12}>
                <TextField
                  fullWidth
                  label={detailsLabel}
                  value={accountNumber}
                  onChange={(e) => setAccountNumber(e.target.value)}
                />
              </Grid>
              <Grid size={12}>
                <TextField
                  fullWidth
                  label='Nombre del propietario'
                  value={ownerName}
                  onChange={(e) => setOwnerName(e.target.value)}
                />
              </Grid>
            </>
          )}
          {selectedPaymentType && (
            <Grid size={12}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Switch
                      checked={isDefault}
                      onChange={(e) => setIsDefault(e.target.checked)}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  }
                  label='Usar como predeterminado'
                />
              </FormControl>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button onClick={handleAdd} variant='contained' color='primary'>
          {editMode ? 'Guardar' : 'Agregar'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AddEmployeePaymentTypeDialog
