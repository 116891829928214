import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Autocomplete from '@mui/material/Autocomplete'
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid2'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { AxiosError } from 'axios'
import dayjs, { Dayjs } from 'dayjs'
import { FC, useEffect, useState } from 'react'
import { axiosPrivate } from '../../api/axios'
import { endpoints } from '../../api/constants'
import { useAuth } from '../../context/AuthProvider'
import { useSnackbar } from '../../context/SnackbarProvider'
import { Employee, getDisplayName } from '../../types/Employee'
import { Farm } from '../../types/Farm'
import { JournalDailyWorkPaymentStatus, getJournalDailyWorkPaymentStatusLabel } from '../../types/Journal'

interface DailyWorkFiltersProps {
  selectedPaymentStatus: JournalDailyWorkPaymentStatus
  onPaymentStatusChange: (newStatus: JournalDailyWorkPaymentStatus) => void  
  selectedFarm: Farm | null
  setSelectedFarm: (farm: Farm | null) => void
  selectedEmployee: Employee | null
  setSelectedEmployee: (employee: Employee | null) => void
  selectedFromDate: Dayjs | null
  setSelectedFromDate: (date: Dayjs | null) => void
  selectedToDate: Dayjs | null
  setSelectedToDate: (date: Dayjs | null) => void
}

const DailyWorkFilters: FC<DailyWorkFiltersProps> = ({
  selectedPaymentStatus,
  onPaymentStatusChange,
  selectedFarm,
  setSelectedFarm,
  selectedEmployee,
  setSelectedEmployee,
  selectedFromDate,
  setSelectedFromDate,
  selectedToDate,
  setSelectedToDate,
}) => {
  const selectedPaymentStatusString = selectedPaymentStatus.toString()
  const [farms, setFarms] = useState<Farm[]>([])
  const [employees, setEmployees] = useState<Employee[]>([])
  const { auth } = useAuth()
  const { selectedTenant } = auth
  const { showError } = useSnackbar()

  useEffect(() => {    
    let isMounted = true
    const controller = new AbortController()

    const getFarms = async () => {
      try {
        const response = await axiosPrivate.get(
          `${endpoints.getFarms}${selectedTenant?.id}`,
          { signal: controller.signal }
        )
        if (isMounted) {
          setFarms(response.data)
        }
      } catch (err: any) {
        const error = err as AxiosError
        if (error.name !== 'CanceledError') {
          showError('Error al cargar las fincas')
        }
      }
    }

    const getEmployees = async () => {
      try {
        const response = await axiosPrivate.get(
          `${endpoints.getEmployees}${selectedTenant?.id}`,
          { signal: controller.signal }
        )
        if (isMounted) {         
          setEmployees(response.data)
        }
      } catch (err: any) {
        const error = err as AxiosError
        if (error.name === 'CanceledError') {          
        } else {
          showError('Error al cargar los empleados')
        }
      }
    } 
  
    getFarms()
    getEmployees()
  
    return () => {
      isMounted = false
      controller.abort()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTenant?.id])

  const handlePaymentStatusChange = (event: SelectChangeEvent) => {    
    const newStatus = parseInt(event.target.value, 10)
    if (newStatus === JournalDailyWorkPaymentStatus.Paid || newStatus === JournalDailyWorkPaymentStatus.All){      
      if(selectedFromDate === null){
        const currentDate = dayjs()
        setSelectedFromDate(currentDate.subtract(1, 'month'))
      }
    } else if (newStatus === JournalDailyWorkPaymentStatus.PendingPayment){
      setSelectedFromDate(null)      
    }    
    onPaymentStatusChange(newStatus)
  }

  return (
    <div style={{ marginBottom: '40px' }}>
      <Accordion sx={{ borderRadius: '12px' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1-content'
          id='panel1-header'
          sx={{
            padding: '1',
            minHeight: '0 !important',
            '& .MuiAccordionSummary-content': { margin: '8px 0' },
          }}
        >
          <Typography
            fontWeight='bold'
            sx={{ fontSize: '0.900rem', lineHeight: '1.2' }}
          >
            Filtrar
          </Typography>
        </AccordionSummary>

        <AccordionDetails>
          <Grid container spacing={0.7} sx={{ my: 0, mx: 1, p: 0 }}>
            <Grid size={{ xs: 12, md: 4 }}>
              <FormControl sx={{ 
                  minWidth: 200 ,
                  ml: { xs: 0, md: 2 },
                  mt: { xs: 1, md: 0 },
                }}
                size='small' fullWidth>
                <InputLabel id='estado-pago-select-label'>Estado</InputLabel>
                <Select
                  labelId='estado-pago-select-label'
                  id='estado-pago-select'
                  value={selectedPaymentStatusString}
                  label='Estado'
                  onChange={handlePaymentStatusChange}
                >
                  <MenuItem
                    value={JournalDailyWorkPaymentStatus.PendingPayment.toString()}
                  >
                    {getJournalDailyWorkPaymentStatusLabel(
                      JournalDailyWorkPaymentStatus.PendingPayment
                    )}
                  </MenuItem>                  
                  <MenuItem
                    value={JournalDailyWorkPaymentStatus.Paid.toString()}
                  >
                    {getJournalDailyWorkPaymentStatusLabel(
                      JournalDailyWorkPaymentStatus.Paid
                    )}
                  </MenuItem>                 
                  <MenuItem
                    value={JournalDailyWorkPaymentStatus.All.toString()}
                  >
                    {getJournalDailyWorkPaymentStatusLabel(
                      JournalDailyWorkPaymentStatus.All
                    )}
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid size={{ xs: 12, md: 4 }}>
              <Autocomplete
                size='small'
                sx={{ 
                  minWidth: 200 ,
                  ml: { xs: 0, md: 2 },
                  mt: { xs: 1, md: 0 },
                }}
                fullWidth
                disablePortal
                id='combo-box-finca'
                options={farms}
                getOptionLabel={(farm) => farm.name}
                isOptionEqualToValue={(option: Farm, value: Farm) =>
                  option.id === value.id
                }
                value={selectedFarm}
                onChange={(event, newValue) => {
                  setSelectedFarm(newValue)
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label='Finca'
                  />
                )}
              />
            </Grid>
            <Grid size={{ xs: 12, md: 4 }}>
              <Autocomplete
                size='small'
                sx={{ 
                  minWidth: 200 ,
                  ml: { xs: 0, md: 2 },
                  mt: { xs: 1, md: 0 },
                }}
                fullWidth
                disablePortal
                id='combo-box-empleado'
                options={employees}
                getOptionLabel={(employee) => getDisplayName(employee)}
                isOptionEqualToValue={(option: Employee, value: Employee) =>
                  option.id === value.id
                }
                value={selectedEmployee}
                onChange={(event, newValue) => {
                  setSelectedEmployee(newValue)
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label='Empleado'
                  />
                )}
              />
            </Grid>
            <Grid size={{ xs: 12, md: 4 }}>
              <DatePicker
                format='DD/MM/YYYY'
                label='Fecha desde'
                value={selectedFromDate}
                onChange={(newDate) => setSelectedFromDate(newDate)}
                slotProps={{
                  actionBar: {
                    actions: ['clear', 'accept'],
                  },
                }}
                sx={{
                  width: '100%',
                  ml: { xs: 0, md: 2 },
                  mt: { xs: 1, md: 0 },
                  '.MuiInputBase-root': {
                    // size='small'
                    height: '40px',
                    fontSize: '0.875rem',
                  },
                  '.MuiInputLabel-root': {
                    fontSize: '0.875rem',
                  },
                }}
              />
            </Grid>
            <Grid size={{ xs: 12, md: 4 }}>
              <DatePicker
                format='DD/MM/YYYY'
                label='Fecha hasta'
                value={selectedToDate}
                onChange={(newDate) => setSelectedToDate(newDate)}
                slotProps={{
                  actionBar: {
                    actions: ['clear', 'accept'],
                  },
                }}
                sx={{
                  width: '100%',
                  ml: { xs: 0, md: 2 },
                  mt: { xs: 1, md: 0 },
                  '.MuiInputBase-root': {
                    // size='small'
                    height: '40px',
                    fontSize: '0.875rem',
                  },
                  '.MuiInputLabel-root': {
                    fontSize: '0.875rem',
                  },
                }}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

export default DailyWorkFilters
